.read-more {
    .not-expanded-text {
        height: 149px;
        overflow: hidden;
    }
    .expanded-text {
        height: 100%;
    }
    .read-more-text {
        position: relative;
        hr.hr-with-gradient::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100px;
            margin-top: -101px;
            background: linear-gradient(2deg, rgba(255, 255, 255, 1) 0%, rgba(238, 130, 238, 0) 100%);
            margin-left: -50%;
        }
        .read-more-button {
            text-align: center;
            cursor: pointer;
            hr {
                border-top: 20px solid #ffffff;
                border-bottom: 1px solid #e2e8f0;
            }
            span.read-more-arrow {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                svg {
                    font-size: 1.2em;
                    margin-top: 15px;
                }
            }
        }
    }
}