.transfer-page-text {
    .transfer-notes {
        h2 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            margin-bottom: 10px;
        }
        a {
            color: #2563eb;
            text-decoration: underline;
        }
    }
}