.responsive-table-section {
    table {
        thead {
            tr {
                background: #111d4d;
                th {
                    color: #ffffff;
                    line-height: 20px;
                    padding: 5px 0;
                }
            }
        }
        tbody {
            tr {
                td {
                    border: 1px solid #e2e8f0;
                    line-height: 20px;
                    text-align: center;
                    padding: 3px 0;
                }
            }
            tr:nth-child(odd) {
                background: #f1f5f9;
            }
        }
    }
}