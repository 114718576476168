.style-hotel-page {
    .distances-p {
        svg {
            display: inline-block;
            font-size: 1.5rem;
            line-height: 2rem;
            vertical-align: middle;
            margin-right: 8px;
        }
    }
}

.style-location-page {
    p {
        margin-top: 20px !important;
        font-size: 1.1rem;
    }
    .distances-p {
        span {
            display: block;
            margin-top: 10px;
            svg {
                display: inline-block;
                font-size: 1.4rem;
                line-height: 2rem;
                vertical-align: middle;
                margin-right: 8px;
            }
        }
    }
}

.responsive-table {
    @media (min-width: 640px) {
        table {
            display: inline-table !important;
        }
        thead tr:not(:first-child) {
            display: none;
        }
    }
    td:not(:last-child) {
        border-bottom: 0;
    }
    th:not(:last-child) {
        border-bottom: 2px solid rgba(0, 0, 0, .1);
    }
}

.responsive-table2 {
    tbody {
        tr {
            padding: 5px;
            text-align: center;
            border: 1px solid #e2e8f0 !important;
            td {
                padding: 5px;
            }
        }
        tr:nth-child(odd) {
            background: #f1f5f9;
        }
    }
    thead {
        tr {
            background: #111d4d;
            th {
                color: #fff;
                padding: 5px;
            }
        }
    }
}

.facilities-page {
    .facilities-section {
        margin-bottom: 1rem;
        h1 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}