.bg-salmon {
    overflow: hidden;
}

.modal-outer {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 10038;
    align-items: center;
    justify-content: center;
    .modal-screen {
        background-color: #fff;
        padding: 25px;
        position: relative;
        width: auto;
        margin: 10px;
        pointer-events: none;
        max-width: 700px;
        box-sizing: border-box;
        margin: 30px auto;
        display: block;
        z-index: 100040;
        font-size: 0.9em;
        pointer-events: all;
        text-align: left;
        .outer-close-button {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            summary {
                display: inline-block;
                width: calc(100% - 30px);
                list-style-type: none;
                font-size: 1.8em;
                line-height: 1em;
                font-weight: 800;
            }
            .close-modal-button {
                display: inline-block;
                width: 30px;
                height: 30px;
                border: none;
                line-height: 20px;
                margin-bottom: 0;
                text-transform: lowercase;
                padding: 0;
                pointer-events: all;
                svg {
                    path {
                        stroke: red;
                    }
                }
            }
        }
    }
}